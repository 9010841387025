@import "./size.scss";
@import "./colors.scss";

.login-component {
  text-align: center;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: #eee;
  .login-box {
    height: 70%;
    width: 50%;
    background-color: #fff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    transition: 0.3s;
    display: flex;
    flex-direction: row;
    position: relative;

    .left-container {
      width: 50%;
      display: flex;
    }

    .right-container {
      display: flex;
      width: 50%;
      justify-content: center;

      .login-container {
        width: 100%;
        .close-icon {
          margin: 10px;
          margin-top: 20px;
          cursor: pointer;
        }
        .login-header {
          width: 100%;
          margin: auto;
          color: #444;
          font-size: 15px;
          overflow: hidden;
          background: $white;
        }
        .header-logo {
          display: flex;
          align-items: center;
          margin-top: 10px;
          color: #444;
          font-size: 15px;
          overflow: hidden;
          background: $white;
        }
        .logo {
          height: 20px;
          margin: 10px;
          margin-top: 20px;
          margin-left: 20px;
          object-fit: contain;
          width: 100px;
        }

        .login-form {
          padding: 20px 0;
          background: $white;
          text-align: center;
        }

        input {
          width: 70%;
          padding: 10px;
          outline: none;
          font-size: 13px;
          margin: 10px auto;
          border-radius: 3px;
          border: 1px solid #3d4977;
        }

        button {
          width: 75%;
          color: $white;
          border: none;
          padding: 10px;
          outline: none;
          cursor: pointer;
          font-size: 13px;
          border-radius: 3px;
          margin-top: 20px;
          background: #3d4977;
        }

        button:active {
          background: #3d4977;
        }

        .login-forget {
          color: $blue;
          cursor: pointer;
          text-align: center;
          font-size: 14px;
          margin-top: 10px;
        }

        .email-input {
          border: 0.5px solid rgb(248, 25, 25);
        }
        .email-input::placeholder {
          color: red;
        }

        .password-input {
          border: 0.5px solid rgb(248, 25, 25);
        }
        .password-input::placeholder {
          color: red;
        }

        .form-error {
          width: 75%;
          background-color: #fadcde;
          font-size: 12px;
          border-radius: 5px;
          padding: 5px;
          margin: auto;
        }
        .form-success {
          width: 75%;
          background-color: #dafadd;
          font-size: 12px;
          border-radius: 5px;
          padding: 5px;
          margin: auto;
        }
      }
    }
  }
}
