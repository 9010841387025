.navbar-page {
  .main-nav {
    display: flex;
    padding: 12px 0;
    background: $blue;
    justify-content: space-between;

    .logo {
          margin: auto 20px;
          cursor: pointer;
          height: 42px;
          width: 70px;  
    }
    
    .nav-links {
      margin: 09px auto auto 10px;
      
      span {
        color: $white;
        cursor: pointer;
        font-size: 18px;
        padding: 21px 10px;
        transition: all 1.5s ease;

        .icon {
          font-size: 16px;
          margin-right: 03px;
        }
      }

      span:hover, .active {
        background-color: $blueActive;
      }
    }
    
    .search {
      input {
        background: $search;
        font-size: 16px;
        padding: 10px;
        outline: none;
        border: none;
        color: $gray;
      }

      .icon {
        margin: auto 20px;
        margin-top: 10px;
        font-size: 20px;
        cursor: pointer;
        color: $gray;
      }

      background: $search;
      border-radius: 2px;
    }

    .profile-picture {
      box-shadow: 00px 00px 10px 00px rgb(128, 128, 128);
      background: rgb(236, 236, 236);
      border-radius: 50%;
      margin: auto 20px;
      cursor: pointer;
      height: 42px;
      width: 42px;
    }
    
    .pop-up {
      top: 68px;
      z-index: 1;
      right: 05px;
      width: 225px;
      color: $white;
      background: $blue;
      position: absolute;
      box-shadow: 00px 04px 02px 00px $gray;
      
      .account {
        transition: all 1.5s ease;
        padding: 10px 00px;
        font-size: 16px;
        cursor: pointer;

        .icon {
          margin: 00px 10px;
        }
      }

      .account:hover, .active {
        color: $white;
        background-color: $blueActive;
      }
    }
    
    .pop-up::before {
      position: absolute;
      content: "";
      top: -13%;
      width: 0;
      height: 0;
      left: 76%;
      float: right;
      border-right: 15px solid $blue;
      border-top: 15px solid transparent;
    }

    .pop-up::after {
      position: absolute;
      content: "";
      top: -13%;
      width: 0;
      height: 0;
      left: 82%;
      float: right;
      border-left: 15px solid $blue;
      border-top: 15px solid transparent;
    }
  }
}
