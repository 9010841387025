@import "./size.scss";
@import "./colors.scss";

.loan-root {
    .mapdiv {
        flex: 1;
        height: 'calc(100vh-60)';
        display: flex;
        justify-content: flex-end;
    }
    .side-view {
        width: 324px;
        top: 64px;
        bottom: 0;
        left: 0;
        background-color: white;
        position: absolute;
        border-right: 1px solid #000;
    }
    .auto-complete-inner {
        padding-left: 12px;
        padding-right: 12px;
    }
    .map-container {
        border: 20px solid #eee;
    }
}
