$small: 10px;
$large: 20px;
$extra: 25px;
$medium: 15px;
$commonHeight: 800px;

$topBottomMargin: 25px;
$leftRightMargin: 94%;

$topBottomMarginOne: 50px;
$leftRightMarginOne: 90%;

$TableHeaderMargin: 10px;
$TableleftRightPercetange: 95%;
$TableHeaderLeftRightPercetange: 98%;
$asideTableHeaderLeftRightPercetange: 90%;
